/** @format */

.save {
    background: lightgray;
    color: "white";
    font-family: "Merriweather", serif;
    border: none;
}

.save:hover {
    background: rgb(161, 159, 159);
    color: rgb(255, 255, 255);
    font-family: "Merriweather", serif;
}

.updateShadow {
    box-shadow: 0 8px 4px 0 #afc1c7, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}