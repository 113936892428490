/** @format */

table,
th,
td {
    /* border: 1px solid rgb(165, 164, 164); */
    /* background: rgb(236, 236, 236); */
}

.main-div-ca {
    background: white;
}

.app-heading {
    text-align: center;
    padding: 30px;
    font-family: "Merriweather", serif;
}

.heading-row {
    margin: 10px 10px 40px 10px;
}

.app-name-tag {
    /* padding-right: 0px; */
    /* margin-right: 10px !important; */
    text-align: right;
    /* float: ; */
    /* margin */
    padding-left: 0px;
    margin-top: 5px;
    /* float: right; */
}

.app-btn {
    border: none;
    background: rgb(53, 117, 201);
    color: #ffffff !important;
    /* font-weight: 100; */
    /* margin-bottom: 2px; */
    padding: 5px;
    border: 2px solid rgb(53, 117, 201);
    /* text-transform: uppercase; */
    border-radius: 4px;
    margin: 2px;
    display: inline-block;
    transition: all 0.3s ease 0s;
}

.app-btn:hover {
    color: rgb(53, 117, 201) !important;
    /* font-weight: 700 !important; */
    letter-spacing: 2px;
    background: none;
    position: relative;
    border: 2px solid rgb(53, 117, 201);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.3s ease 0s;
}

#app-name {
    float: left;
    size: 10;
    height: 40px;
    margin-left: -10px !important;
}


/* label {
  float: left;
}
.new_app_btn {
  float: right;
} */

.table-row {
    margin-right: 20px !important;
}

.table-row:hover {
    /* background: white; */
    transform: scale(1);
    border: 1px solid rgb(110, 110, 110) !important;
    -webkit-transform: scale(1);
    background: #ffffff;
    -moz-transform: scale(1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.load-btn {
    color: rgb(61, 114, 61) !important;
    background: #ffffff;
    padding: 5px;
    border: 3px solid rgb(61, 114, 61) !important;
    /* display: inline-block; */
    transition: all 0.4s ease 0s;
}

.load-btn:hover {
    color: #ffffff !important;
    background: rgb(61, 114, 61);
    border-color: rgb(61, 114, 61) !important;
    transition: all 0.4s ease 0s;
}

.json-btn {
    color: rgb(109, 109, 243) !important;
    background: #ffffff;
    padding: 5px;
    border: 3px solid rgb(109, 109, 243) !important;
    /* display: inline-block; */
    transition: all 0.4s ease 0s;
}

.json-btn:hover {
    color: #ffffff !important;
    background: rgb(109, 109, 243);
    border-color: rgb(109, 109, 243) !important;
    transition: all 0.4s ease 0s;
}

.delete-btn {
    float: right;
    margin-right: 30px;
    transition: all 0.4s ease 0s;
}

.delete-btn:hover {
    border-radius: 20px;
    transition: all 0.4s ease 0s;
    /* width: 200px; */
    /* letter-spacing: 2px; */
}