.pricing {
  /* background-color: rgb(148, 148, 135); */
  /* background: transparent url("../SiteImages/pricing5.jpg") no-repeat center fixed; */
  background-size: cover;
  width: 100%;
  height: auto;
}
#paragraph {
  font-size: 2.8em;
  font-family: "Stag Web";

  line-height: 1.3em;
  margin: 0;
  text-rendering: optimizeLegibility;
  font-weight: 500;
}
#started {
  background-color: rgb(53, 117, 201);
  width: 60%;
  color: white;
  margin: 30px;
  font-size: 20px;
}
#started:hover {
  background-color: rgb(20, 27, 41);
  color: rgb(255, 255, 255);
}
.banner {
  background: rgb(53, 56, 59);
  margin: 20px 0px 70px 0px;
}
.text {
  color: #fff;
  text-transform: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 500;
  padding: 20px;
  margin: 20px;
}
.text > b {
  color: rgb(53, 117, 201);
}
.faqs {
  margin: 20px;
  background: rgb(53, 117, 201);
  /* rgb(53, 117, 201) 0*/
  width: 100%;
  margin-left: 0px;
  text-align: center;
}
.ques {
  margin-top: 40px;
  margin-bottom: 40px;
  /* text-align: center; */
}
.pricing-text {
  margin: 10px;
  padding: 50px;
  color: black;
  text-align: center;
}
.pricing-btn {
  background: rgb(53, 117, 201);
  color: #282a2d;
  font-size: 18px;
  width: 50%;
  height: 50px;

  line-height: 50px;
  padding: 0;
  margin: 30px 20px 20px 100px;

  text-align: center;
  /* -webkit-box-sizing: border-box!important;
    -moz-box-sizing: border-box!important; */
  box-sizing: border-box !important;
  border: none;
}
.pricing-btn:hover {
  background: black;
  color: rgb(53, 117, 201);
}
.price-table-wrapper {
  font-family: "Lato", sans-serif;
  text-align: center;
  margin: 60px 30px 60px 30px;
}
.featured-table {
  box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);
}

.pricing-table {
  display: inline-block;
  border: 3px solid rgb(53, 117, 201);
  border-radius: 10px;
  background: rgb(20, 27, 41);
  margin: 20px;
  transition: all 0.3s ease-in-out;
}
.pricing-table:hover {
  box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);
}
.pricing-table-header {
  padding: 20px;
  font-size: 20px;
  color: beige;
  background: rgb(29, 28, 28);
}
.pricing-table-price {
  color: rgb(53, 117, 201);
  padding: 20px;
  margin: auto;
  font-size: 40px;
  font-weight: 500;
}
.pricing-table > a {
  text-decoration: none;
  font-size: 22px;
}
.pricing-table-button {
  display: block;
  background: rgb(53, 117, 201);
  padding: 20px;
  color: white;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.pricing-table-button::before {
  position: absolute;
  left: -20%;
  top: -10%;
  content: "";
  width: 60%;
  height: 220%;
  transform: rotate(-30deg);
  background: beige;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}
.pricing-table-button::after {
  position: absolute;
  content: ">";
  top: 0;
  right: 0;
  font-size: 25px;
  padding: 15px;
  padding-right: 40px;
  color: rgb(53, 117, 201);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pricing-table-button:hover {
  background: beige;
  color: black;
}
.pricing-table-button:hover::before {
  top: -80%;
  transform: rotate(0deg);
  width: 100%;
}
.pricing-table-button:hover::after {
  opacity: 1;
  padding-right: 15px;
}

.pricing-table-list {
  padding: 20px;
  color: beige;
}
.pricing-table-list > li {
  padding: 15px;
  border-bottom: 1px solid #c8c8c8;
  list-style-type: none;
}
.pricing-table-list:last-child {
  border: none;
}
