/** @format */

.parent {
    position: relative;
    /* border: 2px solid blue !important; */
    /* overflow: visible !important; */
}

.all {
    position: absolute;
    border: 2px solid blue;
    width: 15px;
    height: 15px;
    cursor: move;
}

.bottom-right {
    right: -8px;
    bottom: -8px;
}

.top-right {
    right: -8px;
    top: -8px;
}

.top-left {
    top: -8px;
    left: -8px;
}

.bottom-left {
    bottom: -8px;
    left: -8px;
}

.bottom-center {
    bottom: -8px;
    left: 50%;
}

.top-center {
    top: -8px;
    left: 50%;
}