.makeClickable {
  cursor: pointer;
  /* border: 0.5px solid white; */
  /* background-color: #7285A5; */
  color: white;
}


.small[type="text"] {
  /* margin: 50px; */
  width: 45px;

  color: green;
  background: black;
  border: 0;
  text-align: right;
  font-size: 12px;
}

.small:focus {
  /* margin: 50px; */
  width: 45px;
  outline: none;
  color: green;
  background: yellow;
  border: 0;

}

.property {
  font-size: 12px;
  display: block;
  padding: 4px;
}

.myTable {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  vertical-align: baseline;
  background: rgb(201, 217, 252);
  color: black;
  border: 2px solid lightgrey;

}

.myTable>tr>td {
  font-size: 9.5px;
}

.tableZone {

  /* position: fixed; */

  height: 800px;
  overflow-y: auto;
  background: #343A40;
  color: #CCCECF;
  box-shadow: -6px 9px 48px -6px rgba(160, 187, 214, 1);


}

.tableZone::-webkit-scrollbar {
  /* background: black; */
  display: none;
}

/* .tableZone::-webkit-scrollbar-thumb {
  background: green
} */

.changingHover:hover {
  background-image: linear-gradient(to right top,
      #74ad83,
      #00a097,
      #008dd4,
      #006eff,
      #2312eb);
}