.signup-page {
    /* background-image: url("../SiteImages/signup1.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    font-family: "Numans", sans-serif;
}

.container {
    height: 100%;
    /* align-content: right; */
}

.text-side {
    text-align: center;
    margin-top: 250px;
    /* margin-left: 50px; */
}

.text-side>h2 {
    /* float: right; */
    margin-left: 80px;
}

.picture-side>img {
    /* margin: 20px 0px 0px 20px; */
    margin-right: 30px;
    margin-top: 30px;
    float: right;
}

.signup-card {
    padding: 25px;
    /* margin-right: 0px; */
    background: transparent;
    /* border-left: 5px solid grey; */
    /* float: right; */
    /* margin-left: 10px; */
    /* border-bottom: 5px solid grey; */
    /* border-right: 5px solid grey; */
    /* border-top: 5px solid grey; */
    /* box-shadow: 1px 1px 2px rgb(39, 39, 39), 0 0 25px rgb(25, 25, 41),
    0 0 5px darkblue; */
    height: 800px;
    /* margin-top: auto; */
    /* margin-top: 80px; */
    /* margin-bottom: auto; */
    /* margin-right: 50px; */
    width: 500px;
    /* justify-content: center !important; */
}

.signup-card-footer {
    margin-top: 60px;
    /* margin-bottom: 0px; */
    /* background: #313131; */
}

.agreement {
    color: #313131;
}

.agree-check {
    margin-right: 10px;
    margin-top: 20px;
}

.agree-group {
    margin-top: 20px;
    margin: 10px;
}

.input-group>.form-control {
    border-bottom-left-radius: 0;
    height: auto;
}

.social span {
    font-size: 40px;
    margin-left: 10px;
}

.fa-facebook-square {
    color: rgb(66, 103, 178);
}

.fa-facebook-square:hover {
    color: rgb(66, 70, 178);
}

.fa-google-plus-square {
    color: rgb(221, 75, 57);
}

.fa-google-plus-square:hover {
    color: rgb(221, 30, 57);
}

.fa-twitter-square {
    color: rgb(56, 161, 243);
}

.fa-twitter-square:hover {
    color: rgb(56, 120, 243);
}

.icon:hover {
    cursor: pointer;
}

.social p {
    margin-top: 20px;
}

.signup-card-header b {
    margin: 10px 0px 10px 0px;
    color: rgb(70, 68, 68);
    font-size: 40px;
}

.signup-card-body {
    margin: 10px;
}

.extra-styles span {
    width: 50px;
    background-color: rgb(53, 117, 201);
    color: black;
    border: 0 !important;
}

.sample span {
    width: 15px;
    background-color: #E8F0FE;
    color: white;
    border: 0 !important;
}

.info span {
    width: 15px;
    background-color: gray;
    color: black;
    border: 0 !important;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

input:invalid {
    border: 0.5px solid red;
}

.signup_btn {
    color: black;
    background-color: rgb(53, 117, 201);
    width: 100px;
    align-content: center;
    margin-top: 30px;
}

.signup_btn:hover {
    color: white;
    background-color: black;
}