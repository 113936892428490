@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto:wght@700&display=swap);
.contactUs{
    /* width: 100%; */
    font-family: "Numans", sans-serif;
    margin-left:20px;
    
}
#submit_button{
    background: rgb(53, 117, 201);
    color:white;
    border-radius: 5px;
    padding:10px;
    border:none;
}
#submit_button:hover{
    background: rgb(103, 147, 204);
    color:white;
    border-radius: 5px;
    padding:10px;
    border:none;
}
.carouselButton {
  border: none;
  border-radius: 3px;
  padding: 7px;
  background-color: rgb(53, 56, 59);
  color: white;
}
.carouselButton:hover {
  background-color: white;
  color: black;
}
.fiveSteps {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 50px;
}
.fiveStepsHeading {
  margin-top: 45px;
}
.videoDiv {
  background-color: rgb(53, 56, 59);
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 20px;
}
.VideoDivButton {
  background: rgb(53, 117, 201);
  color: rgb(233, 233, 233);
  font-size: 18px;
  width: 45%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  margin-left: 128px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
}
.VideoDivButton:hover {
  background: rgb(97, 130, 173);
  color: white;
  border: 1px solid rgb(97, 130, 173);
}
.VideoDivAlign {
  text-align: center;
  margin-top: 30px;
}
.iconsHome {
  font-size: 50px;
  color: rgb(53, 117, 201);
}
.iconsHome:hover {
  color: rgb(246, 200, 0);
}
.iconsDiv {
  margin-top: 100px;
  margin-bottom: 80px;
}
.banner {
  background: rgb(53, 56, 59);
}
.Banner-Btn {
  background: rgb(53, 117, 201);
  color: black;
  font-size: 18px;
  width: 50%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  margin: 30px 20px 20px 100px;
  text-align: center;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
}
.Banner-Btn:hover {
  background: black;
  color: rgb(53, 117, 201);
}
.Banner-text {
  color: #fff;
  text-transform: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 500;
  padding: 20px;
  margin: 20px;
}
.Banner-text > b {
  color: rgb(53, 117, 201);
}
.MobileDiv {
  margin-top: 50px;
  margin-bottom: 100px;
}
.mobDivStyle {
  padding-top: 30px;
}
.mobDivButton {
  background: rgb(53, 117, 201);
  color: black;
  font-size: 18px;
  width: 48%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  text-align: center;
  box-sizing: border-box;
  border: solid 2px black;
  border-radius: 5px;
}
.mobDivButton:hover {
  background: rgb(97, 130, 173);
  color: black;
  border: solid 2px black;
}
.mobDivButton2 {
  background: whitesmoke;
  color: black;
  font-size: 18px;
  width: 48%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  margin-left: 5px;
  text-align: center;
  box-sizing: border-box;
  border: solid 2px black;
  border-radius: 5px;
}
.mobDivButton2:hover {
  background: rgb(223, 219, 219);
  color: black;
  border: solid 2px black;
}
.faqHome {
  margin: 50px 20px 20px 20px;
  background: rgb(53, 117, 201);
  color: rgb(53, 56, 59);
  width: 100%;
  text-align: center;
}
.faqHomeQues {
  margin-top: 40px;
  margin-bottom: 40px;
  /* text-align: center; */
}
.MotivationHeading {
  color: black;
}
.motivation-text {
  text-align: left;
  color: black;
  margin: 10px;
  padding: 30px;

  -webkit-text-decoration-style: solid;

          text-decoration-style: solid;
}
.motivationBackground {
  background-image: linear-gradient(
    to right,
    #ffffff,
    #eafaff,
    #3b6edd,
    #0f57f1
  );
}

.footer {
  background-color: rgb(20, 27, 41);

  padding: 20px;
  color: aliceblue;
  text-align: center;
}
.FooterHeadings {
  color: rgb(53, 117, 201);
}
.section {
  text-align: left;
  padding: 20px;
  color: white;
}
.section a {
  text-decoration: none;
  color: white;
}

.copyright {
  text-align: center;
  background-color: #1e242b;
  padding: 10px;
}
.copyright a {
  text-decoration: none;
  color: white;
}

.login-page {
    background-image: url(/static/media/back.7f97ffa5.jpg);
    /* float: right; */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    font-family: "Numans", sans-serif;
}

.container {
    height: 100%;
    align-content: center;
}

.card {
    /* background-color: rgba(0, 0, 0, 0.8) !important; */
    /* background: rgb(218, 216, 216); */
    opacity: 0.9;
    border-left: 5px solid grey;
    border-bottom: 5px solid grey;
    border-right: 5px solid grey;
    border-top: 5px solid grey;
    box-shadow: 1px 1px 2px rgb(39, 39, 39), 0 0 25px rgb(25, 25, 41), 0 0 5px darkblue;
    /* float: right; */
    /* margin-right: 0px; */
    /* border-right: 1px rgb(53, 117, 201); */
    height: 480px;
    margin-top: 80px;
    margin-bottom: auto;
    width: 450px;
}

.login {
    margin-top: 15px;
    color: white;
}

.input-group>.form-control {
    border-bottom-left-radius: 0;
    height: auto;
}

.social_icon span {
    font-size: 40px;
    margin-left: 10px;
}

.fa-facebook-square {
    color: rgb(66, 103, 178);
}

.fa-facebook-square:hover {
    color: rgb(66, 50, 178);
}

.fa-google-plus-square {
    color: rgb(221, 75, 57);
}

.fa-github-square {
    color: rgb(34, 32, 32);
}

.fa-google-plus-square:hover {
    color: rgb(221, 30, 57);
}

.fa-twitter-square {
    color: rgb(56, 161, 243);
}

.fa-twitter-square:hover {
    color: rgb(56, 120, 243);
}

.fa-github-square:hover {
    color: rgb(8, 8, 8);
}


/* .icon:hover {
    color: rgb(53, 117, 201);
    cursor: pointer;
  } */

.card-header b {
    color: rgb(70, 68, 68);
    margin-bottom: 25px;
    /* font-family: "stag Web"; */
    font-size: 40px;
}

.card-header {
    margin-bottom: 20px;
}

.social_icon {
    margin-left: 0px;
}


/* .social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
} */

.extra-styles span {
    width: 50px;
    background-color: rgb(53, 117, 201);
    color: black;
    border: 0;
}

.sample span {
    width: 50px;
    background-color: #E8F0FE !important;
    color: rgb(77, 91, 100) !important;
    /* border:1px solid #E8F0FE !important; */
    /* border: 0 !important; */
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    justify-content: center;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn {
    color: black;
    background-color: rgb(53, 117, 201);
    border-radius: 3px;
    border: none;
    padding: 5px;
    margin: 15px 0px 0px 0px;
    width: 100px;
    align-content: center;
}

.login_btn:hover {
    color: white;
    background-color: rgb(0, 0, 0);
}

.link {
    color: black;
}

.link a {
    margin-left: 4px;
}

.card-footer {
    margin-top: 0px;
}
.signup-page {
    /* background-image: url("../SiteImages/signup1.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    font-family: "Numans", sans-serif;
}

.container {
    height: 100%;
    /* align-content: right; */
}

.text-side {
    text-align: center;
    margin-top: 250px;
    /* margin-left: 50px; */
}

.text-side>h2 {
    /* float: right; */
    margin-left: 80px;
}

.picture-side>img {
    /* margin: 20px 0px 0px 20px; */
    margin-right: 30px;
    margin-top: 30px;
    float: right;
}

.signup-card {
    padding: 25px;
    /* margin-right: 0px; */
    background: transparent;
    /* border-left: 5px solid grey; */
    /* float: right; */
    /* margin-left: 10px; */
    /* border-bottom: 5px solid grey; */
    /* border-right: 5px solid grey; */
    /* border-top: 5px solid grey; */
    /* box-shadow: 1px 1px 2px rgb(39, 39, 39), 0 0 25px rgb(25, 25, 41),
    0 0 5px darkblue; */
    height: 800px;
    /* margin-top: auto; */
    /* margin-top: 80px; */
    /* margin-bottom: auto; */
    /* margin-right: 50px; */
    width: 500px;
    /* justify-content: center !important; */
}

.signup-card-footer {
    margin-top: 60px;
    /* margin-bottom: 0px; */
    /* background: #313131; */
}

.agreement {
    color: #313131;
}

.agree-check {
    margin-right: 10px;
    margin-top: 20px;
}

.agree-group {
    margin-top: 20px;
    margin: 10px;
}

.input-group>.form-control {
    border-bottom-left-radius: 0;
    height: auto;
}

.social span {
    font-size: 40px;
    margin-left: 10px;
}

.fa-facebook-square {
    color: rgb(66, 103, 178);
}

.fa-facebook-square:hover {
    color: rgb(66, 70, 178);
}

.fa-google-plus-square {
    color: rgb(221, 75, 57);
}

.fa-google-plus-square:hover {
    color: rgb(221, 30, 57);
}

.fa-twitter-square {
    color: rgb(56, 161, 243);
}

.fa-twitter-square:hover {
    color: rgb(56, 120, 243);
}

.icon:hover {
    cursor: pointer;
}

.social p {
    margin-top: 20px;
}

.signup-card-header b {
    margin: 10px 0px 10px 0px;
    color: rgb(70, 68, 68);
    font-size: 40px;
}

.signup-card-body {
    margin: 10px;
}

.extra-styles span {
    width: 50px;
    background-color: rgb(53, 117, 201);
    color: black;
    border: 0 !important;
}

.sample span {
    width: 15px;
    background-color: #E8F0FE;
    color: white;
    border: 0 !important;
}

.info span {
    width: 15px;
    background-color: gray;
    color: black;
    border: 0 !important;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

input:invalid {
    border: 0.5px solid red;
}

.signup_btn {
    color: black;
    background-color: rgb(53, 117, 201);
    width: 100px;
    align-content: center;
    margin-top: 30px;
}

.signup_btn:hover {
    color: white;
    background-color: black;
}
.pricing {
  /* background-color: rgb(148, 148, 135); */
  /* background: transparent url("../SiteImages/pricing5.jpg") no-repeat center fixed; */
  background-size: cover;
  width: 100%;
  height: auto;
}
#paragraph {
  font-size: 2.8em;
  font-family: "Stag Web";

  line-height: 1.3em;
  margin: 0;
  text-rendering: optimizeLegibility;
  font-weight: 500;
}
#started {
  background-color: rgb(53, 117, 201);
  width: 60%;
  color: white;
  margin: 30px;
  font-size: 20px;
}
#started:hover {
  background-color: rgb(20, 27, 41);
  color: rgb(255, 255, 255);
}
.banner {
  background: rgb(53, 56, 59);
  margin: 20px 0px 70px 0px;
}
.text {
  color: #fff;
  text-transform: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 500;
  padding: 20px;
  margin: 20px;
}
.text > b {
  color: rgb(53, 117, 201);
}
.faqs {
  margin: 20px;
  background: rgb(53, 117, 201);
  /* rgb(53, 117, 201) 0*/
  width: 100%;
  margin-left: 0px;
  text-align: center;
}
.ques {
  margin-top: 40px;
  margin-bottom: 40px;
  /* text-align: center; */
}
.pricing-text {
  margin: 10px;
  padding: 50px;
  color: black;
  text-align: center;
}
.pricing-btn {
  background: rgb(53, 117, 201);
  color: #282a2d;
  font-size: 18px;
  width: 50%;
  height: 50px;

  line-height: 50px;
  padding: 0;
  margin: 30px 20px 20px 100px;

  text-align: center;
  /* -webkit-box-sizing: border-box!important;
    -moz-box-sizing: border-box!important; */
  box-sizing: border-box !important;
  border: none;
}
.pricing-btn:hover {
  background: black;
  color: rgb(53, 117, 201);
}
.price-table-wrapper {
  font-family: "Lato", sans-serif;
  text-align: center;
  margin: 60px 30px 60px 30px;
}
.featured-table {
  box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);
}

.pricing-table {
  display: inline-block;
  border: 3px solid rgb(53, 117, 201);
  border-radius: 10px;
  background: rgb(20, 27, 41);
  margin: 20px;
  transition: all 0.3s ease-in-out;
}
.pricing-table:hover {
  box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);
}
.pricing-table-header {
  padding: 20px;
  font-size: 20px;
  color: beige;
  background: rgb(29, 28, 28);
}
.pricing-table-price {
  color: rgb(53, 117, 201);
  padding: 20px;
  margin: auto;
  font-size: 40px;
  font-weight: 500;
}
.pricing-table > a {
  text-decoration: none;
  font-size: 22px;
}
.pricing-table-button {
  display: block;
  background: rgb(53, 117, 201);
  padding: 20px;
  color: white;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.pricing-table-button::before {
  position: absolute;
  left: -20%;
  top: -10%;
  content: "";
  width: 60%;
  height: 220%;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  background: beige;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}
.pricing-table-button::after {
  position: absolute;
  content: ">";
  top: 0;
  right: 0;
  font-size: 25px;
  padding: 15px;
  padding-right: 40px;
  color: rgb(53, 117, 201);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pricing-table-button:hover {
  background: beige;
  color: black;
}
.pricing-table-button:hover::before {
  top: -80%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  width: 100%;
}
.pricing-table-button:hover::after {
  opacity: 1;
  padding-right: 15px;
}

.pricing-table-list {
  padding: 20px;
  color: beige;
}
.pricing-table-list > li {
  padding: 15px;
  border-bottom: 1px solid #c8c8c8;
  list-style-type: none;
}
.pricing-table-list:last-child {
  border: none;
}

.card-about {
  padding: 40px;
  display: block;
  background: rgb(255, 255, 255);
  margin-top:15px;
  /* margin: 20px 0px 20px 0px; */
}
.about-page {
  background: #f5f7fa;
  /* margin-top:10px; */
}
.about {
  margin: 15px;
  padding: 10px;
}
.content {
  padding: 20px;
}
.faq {
  margin: 20px;
  /* background: rgb(246, 200, 81); */
  background: #3575C9;
  width: 100%;
  margin-left: 0px;
  text-align: center;
}
.img-circle {
  border-radius: 50%;
  width: 112px;
}
.leader {
  text-align: center;
  margin-top: 55px;
  color: rgb(61, 60, 60);
}

.main-div {
  background: rgb(245, 242, 232);
}
.heading {
  text-align: center;
  padding: 20px;
  /* color: black !important; */

  font-family: "Times New Roman", Times, serif;
}
.left-side {
  margin: 10px 10px 0px 20px;
  background: rgb(245, 242, 232);
  box-shadow: 0 0 10px #888888;
  /* box-shadow: 0 8px 4px 0 #888888, 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding-bottom: 2px;
  /* background: grey; */
}
.item {
  /* font: bold; */
  /* font: bold 15px/30px Georgia, serif; */
  margin: 0px 10px 10px 10px !important;
  border-left: thick solid rgb(87, 87, 255) !important;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
}
.add-btn-section {
  border: 1px solid rgb(153, 151, 151) !important;
  padding: 0px;
}
.add-input {
  padding: 15px;
  margin: 15px 5px 15px 15px;
  width: 250px;
  border: 1px solid rgb(164, 164, 250);
  border-radius: 5px;
}

.right-side {
  margin: 10px 40px 0px 0px;
  padding: 10px;
  width: 50%;
  box-shadow: 0 0 10px #888888;
  /* box-shadow: 0 8px 4px 0 #888888, 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.remove-btn {
  background: rgb(87, 87, 255) !important;
  display: inline-block;
  color: white !important;
  border: none;
  border-radius: 20px !important;
  transition: all 0.4s ease 0s;
}
.remove-btn:hover {
  background: #ed3330 !important;
  letter-spacing: 1px;
  /* color: white; */
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}
.add-btn {
  border-radius: 50px;
  display: inline-block !important;
  border: none;
  transition: all 0.4s ease 0s;
  border-radius: 20px !important;
  padding: 10px;
  /* float: right; */
  /* align-content: right; */
  margin-bottom: 2px;
  /* margin: 0px 0px 2px 10px; */
  width: 70px;
}
.update-btn {
  border-radius: 50px;
  display: inline-block !important;
  border: none;
  transition: all 0.4s ease 0s;
  border-radius: 20px !important;
  padding: 10px;
  margin-bottom: 2px;
  width: 100px;
}
.add-btn:hover {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}
.column-input {
  padding: 15px !important;
  width: 300px;
  margin: 10px 20px 10px 15px;
  border: 1px solid rgb(164, 164, 250);
  border-radius: 5px;
}
.select_list {
  padding: 5px !important;
  border: 1px solid rgb(164, 164, 250);
  border-radius: 5px;
  width: 150px;
  margin: 10px 20px 10px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.column-add-btn {
  border-radius: 50px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
  width: 70px;
  border-radius: 20px !important;
  padding: 10px;
}
.column-add-btn:hover {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.47);
  transition: all 0.4s ease 0s;
}
.column-item {
  margin: 0px 10px 10px 10px !important;
  /* border-left: thick solid rgb(87, 87, 255) !important; */
  /* padding: 10px; */
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
}
.left-side-heading {
  text-align: center;
  font-size: 20px;
}

/** @format */

table,
th,
td {
    /* border: 1px solid rgb(165, 164, 164); */
    /* background: rgb(236, 236, 236); */
}

.main-div-ca {
    background: white;
}

.app-heading {
    text-align: center;
    padding: 30px;
    font-family: "Merriweather", serif;
}

.heading-row {
    margin: 10px 10px 40px 10px;
}

.app-name-tag {
    /* padding-right: 0px; */
    /* margin-right: 10px !important; */
    text-align: right;
    /* float: ; */
    /* margin */
    padding-left: 0px;
    margin-top: 5px;
    /* float: right; */
}

.app-btn {
    border: none;
    background: rgb(53, 117, 201);
    color: #ffffff !important;
    /* font-weight: 100; */
    /* margin-bottom: 2px; */
    padding: 5px;
    border: 2px solid rgb(53, 117, 201);
    /* text-transform: uppercase; */
    border-radius: 4px;
    margin: 2px;
    display: inline-block;
    transition: all 0.3s ease 0s;
}

.app-btn:hover {
    color: rgb(53, 117, 201) !important;
    /* font-weight: 700 !important; */
    letter-spacing: 2px;
    background: none;
    position: relative;
    border: 2px solid rgb(53, 117, 201);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.3s ease 0s;
}

#app-name {
    float: left;
    size: 10;
    height: 40px;
    margin-left: -10px !important;
}


/* label {
  float: left;
}
.new_app_btn {
  float: right;
} */

.table-row {
    margin-right: 20px !important;
}

.table-row:hover {
    /* background: white; */
    transform: scale(1);
    border: 1px solid rgb(110, 110, 110) !important;
    -webkit-transform: scale(1);
    background: #ffffff;
    -moz-transform: scale(1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.load-btn {
    color: rgb(61, 114, 61) !important;
    background: #ffffff;
    padding: 5px;
    border: 3px solid rgb(61, 114, 61) !important;
    /* display: inline-block; */
    transition: all 0.4s ease 0s;
}

.load-btn:hover {
    color: #ffffff !important;
    background: rgb(61, 114, 61);
    border-color: rgb(61, 114, 61) !important;
    transition: all 0.4s ease 0s;
}

.json-btn {
    color: rgb(109, 109, 243) !important;
    background: #ffffff;
    padding: 5px;
    border: 3px solid rgb(109, 109, 243) !important;
    /* display: inline-block; */
    transition: all 0.4s ease 0s;
}

.json-btn:hover {
    color: #ffffff !important;
    background: rgb(109, 109, 243);
    border-color: rgb(109, 109, 243) !important;
    transition: all 0.4s ease 0s;
}

.delete-btn {
    float: right;
    margin-right: 30px;
    transition: all 0.4s ease 0s;
}

.delete-btn:hover {
    border-radius: 20px;
    transition: all 0.4s ease 0s;
    /* width: 200px; */
    /* letter-spacing: 2px; */
}
.makeClickable {
  cursor: pointer;
  /* border: 0.5px solid white; */
  /* background-color: #7285A5; */
  color: white;
}


.small[type="text"] {
  /* margin: 50px; */
  width: 45px;

  color: green;
  background: black;
  border: 0;
  text-align: right;
  font-size: 12px;
}

.small:focus {
  /* margin: 50px; */
  width: 45px;
  outline: none;
  color: green;
  background: yellow;
  border: 0;

}

.property {
  font-size: 12px;
  display: block;
  padding: 4px;
}

.myTable {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  vertical-align: baseline;
  background: rgb(201, 217, 252);
  color: black;
  border: 2px solid lightgrey;

}

.myTable>tr>td {
  font-size: 9.5px;
}

.tableZone {

  /* position: fixed; */

  height: 800px;
  overflow-y: auto;
  background: #343A40;
  color: #CCCECF;
  box-shadow: -6px 9px 48px -6px rgba(160, 187, 214, 1);


}

.tableZone::-webkit-scrollbar {
  /* background: black; */
  display: none;
}

/* .tableZone::-webkit-scrollbar-thumb {
  background: green
} */

.changingHover:hover {
  background-image: linear-gradient(to right top,
      #74ad83,
      #00a097,
      #008dd4,
      #006eff,
      #2312eb);
}
select {}
.sidebar {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 16px;
}

.sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.right-side {
  margin: 10px;
  padding: 5px;
  /* -moz-border-radius: 5px;
	-webkit-border-radius: 5px;  */
  border: 3px solid black;
  border-style: double;
}

.zero_padding {
  padding: 0px !important;
  margin: 0px !important;
}
/* .gridStructure {
  grid-column-start: 1;
  grid-column-end: 3;
} */
.rightSideIcons {
  padding-bottom: 20px;
  cursor: pointer;
  color: darkslategray;
  margin-top: 30px;
  font-size: 20px;
}

.rightSideIcons:hover {
  color: #0069D9;

}

.showFew>div {
  border: 1px solid lightgray;
}
.center {
    /* display: inline;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0) */
}

.right {
    /* position: relative; */
    float: right;

}

.left {
    /* position: relative; */
    float: left;

}
/**
 * /* OTHER BUTTON
 *
 * @format
 */

.example_1 {
    background: #1d1d1d;
    color: white;
    padding: 10px;
}

.example_2 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    border: none;
}

.example_10 {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: 2px solid white;
    border-radius: 50px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    /* outline: none; */
}


/* OTHER BUTTON */

.example_3 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #60a3bc;
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    border: none;
}


/* OTHER BUTTON */

.example_4 {
    border: none;
    background: #404040;
    color: #ffffff;
    font-weight: 100;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 6px;
    display: inline-block;
}


/* OTHER BUTTON */

.example_5 {
    background: #ed3330;
    border: none;
    padding: 10px;
    border-radius: 10px;
}

.example_6 {
    background: white;
    border: 2px solid rgb(85, 85, 85);
    color: rgb(85, 85, 85);
    padding: 10px;
}

.example_10 {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: 2px solid white;
    border-radius: 50px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    /* outline: none; */
}

.example_7 {
    border-radius: 50px;
    border-style: dotted;
}

.example_8 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    /* padding-bottom: 30px ; */
    text-align: center;
    display: table-cell;
    padding-top: 3px;
    vertical-align: middle;
    background: #9db416;
    color: white;
}

.example_9 {
    border-radius: 5px 25px;
    background: #edb130;
}

.button {
    outline: none;
}

.example_12 {
    border: none;
    background: #0069d9;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 30px;
}


/* Areeb Bhai Css */

.label {
    color: #757575;
    font-family: Verdana;
}

.label-2 {
    color: #757575;
    font-family: sans-serif;
    color: black;
}

.editText {
    background-color: #ff4437;
    border: 0;
    padding: 10px 20px 10px 20px;
    color: white;
}

.editText:focus {
    outline: none;
    box-shadow: none;
    color: black;
    background-color: #dddddd;
}

.editText::-webkit-input-placeholder {
    color: white;
    opacity: 1;
}

.editText::placeholder {
    color: white;
    opacity: 1;
}

.editText:focus::-webkit-input-placeholder {
    color: red;
    opacity: 1;
}

.editText:focus::placeholder {
    color: red;
    opacity: 1;
}

.editText-2 {
    background-color: #ffffff;
    border-top: none;
    outline: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
    padding: 10px 20px 10px 20px;
    color: black;
}


/* .editText-2:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #ff4437;
    color: black;
    background-color: #dddddd;
} */


/* Musa Style Sheet */

.textField_1 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 20px;
    background: white;
}

.textField_2 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    box-sizing: border-box;
    border: 1px solid black;
    background: white;
}

.textField_3 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid black;
    background: rgb(230, 49, 49);
}

.textField_4 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid rgb(41, 163, 41);
    border-radius: 10px;
    background: rgb(41, 163, 41);
    color: black;
}

.textField_5 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid;
    border-radius: 5px;
}

.textField_6 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid rgb(236, 232, 232);
    border-left: 5px solid black;
    background: rgb(236, 232, 232);
}

.textField_1::-webkit-input-placeholder {
    color: black;
}

.textField_1::placeholder {
    color: black;
}

.textField_2::-webkit-input-placeholder {
    color: black;
}

.textField_2::placeholder {
    color: black;
}

.textField_3::-webkit-input-placeholder {
    color: black;
}

.textField_3::placeholder {
    color: black;
}

.textField_4::-webkit-input-placeholder {
    color: black;
}

.textField_4::placeholder {
    color: black;
}

.textField_6::-webkit-input-placeholder {
    color: black;
}

.textField_6::placeholder {
    color: black;
}

.radio-styles {
    width: 40px;
    height: 40px;
}

.checbox_default {
    width: 2.5rem;
    height: 2.5rem;
}
/**
 * /* OTHER BUTTON
 *
 * @format
 */

.example_1 {
    background: #1d1d1d;
    color: white;
    padding: 10px;
}

.example_2 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    border: none;
}

.example_10 {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: 2px solid white;
    border-radius: 50px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    /* outline: none; */
}


/* OTHER BUTTON */

.example_3 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #60a3bc;
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    border: none;
}


/* OTHER BUTTON */

.example_4 {
    border: none;
    background: #404040;
    color: #ffffff;
    font-weight: 100;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 6px;
    display: inline-block;
}


/* OTHER BUTTON */

.example_5 {
    background: #ed3330;
    border: none;
    padding: 10px;
    border-radius: 10px;
}

.example_6 {
    background: white;
    border: 2px solid rgb(85, 85, 85);
    color: rgb(85, 85, 85);
    padding: 10px;
}

.example_10 {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: 2px solid white;
    border-radius: 50px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    /* outline: none; */
}

.example_7 {
    border-radius: 50px;
    border-style: dotted;
}

.example_8 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    /* padding-bottom: 30px ; */
    text-align: center;
    display: table-cell;
    padding-top: 3px;
    vertical-align: middle;
    background: #9db416;
    color: white;
}

.example_9 {
    border-radius: 5px 25px;
    background: #edb130;
}

.button {
    outline: none;
}

.example_12 {
    color: white;
    border: none;
    background: #0069d9;
    font-weight: bold;
    padding: 10px;
}


/* Areeb Bhai Css */

.label {
    color: #757575;
    font-family: Verdana;
}

.label-2 {
    color: #757575;
    font-family: sans-serif;
    color: black;
}

.editText {
    background-color: #ff4437;
    border: 0;
    padding: 10px 20px 10px 20px;
    color: white;
}

.editText:focus {
    outline: none;
    box-shadow: none;
    color: black;
    background-color: #dddddd;
}

.editText::-webkit-input-placeholder {
    color: white;
    opacity: 1;
}

.editText::placeholder {
    color: white;
    opacity: 1;
}

.editText:focus::-webkit-input-placeholder {
    color: red;
    opacity: 1;
}

.editText:focus::placeholder {
    color: red;
    opacity: 1;
}

.checbox_default {
    width: 2.5rem;
    height: 2.5rem;
}
/** @format */

.parent {
    position: relative;
    /* border: 2px solid blue !important; */
    /* overflow: visible !important; */
}

.all {
    position: absolute;
    border: 2px solid blue;
    width: 15px;
    height: 15px;
    cursor: move;
}

.bottom-right {
    right: -8px;
    bottom: -8px;
}

.top-right {
    right: -8px;
    top: -8px;
}

.top-left {
    top: -8px;
    left: -8px;
}

.bottom-left {
    bottom: -8px;
    left: -8px;
}

.bottom-center {
    bottom: -8px;
    left: 50%;
}

.top-center {
    top: -8px;
    left: 50%;
}
/** @format */

.noScrollBar::-webkit-scrollbar {
    display: none !important;
}


/* The device with borders */

div[id^="component"],
div[id^="label"] {
    cursor: grab;
}

.tablet {
    box-sizing: border-box;
    position: relative;
    margin: 0 40px;
    text-align: start;
    background-color: white !important;
    min-height: 600px;
    height: 700px;
    box-shadow: 0px 10px 1px #ddd, 0 10px 20px #ccc;
}


/* The horizontal line on the  top of the device */

.horizontal_layout {
    position: relative;
    min-height: 2%;
}

.canvas-toolbar .left-arrow {
    /* top: 38%; */
    /* left: 25%; */
    /* margin-left: 40px; */
}

.canvas-toolbar .right-arrow {
    /* margin-right: 40px; */
    justify-content: center;
    align-items: center;
}

.box:hover {
    width: 15px;
    height: 15px;
    border: 2px solid #80ddf8;
}

.arrow-container {
    align-items: center;
}

.canvas-toolbar {
    -webkit-user-select: none;
            user-select: none;
    position: relative;
    display: flex;
    /* flex-grow: 1; */
    align-items: center;
    justify-content: center;
    /* display: inline-block; */
    /* text-align: center; */
    /* display: flex; */
    /* align-content: center;
    flex-direction: row;
    vertical-align: middle; */
}

.canvas-toolbar img {
    width: auto;
    height: auto;
    cursor: pointer;
}

.tablet-topbar-styling {
    background: #ffffff;
    width: 100%;
    height: 15px;
    box-shadow: 0px 10px 1px #ddd 0 10px 20px #ccc;
}

.tablet-topbar-styling img {
    float: right;
    height: 15px;
}

.border-childs {
    position: absolute;
    width: 2px;
    height: 2px;
    border: 2px solid #0069d9;
    z-index: 1000031;
    background-color: #0069d9;
    cursor: grab;
}

.top-left-border {
    top: 0;
    left: 0;
}

.top-center-border {
    top: 0;
    left: 50%;
}

.top-right-border {
    top: 0;
    right: 0;
}

.bottom-left-border {
    bottom: -10px;
    left: 0;
    cursor: row-resize;
}

.bottom-center-border {
    bottom: -10px;
    left: 50%;
    cursor: row-resize;
}

.bottom-right-border {
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
}
.navigation {
    background: rgb(161, 159, 159);
    color: rgb(255, 255, 255);
    font-family: 'Merriweather', serif;
    border: none;
}

.navigation:hover {
    background: rgb(99, 98, 98);
    color: rgb(0, 0, 0);
    font-family: 'Merriweather', serif;
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-6-23 2:28:29
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-6-23 2:32:20
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}
/** @format */

.save {
    background: lightgray;
    color: "white";
    font-family: "Merriweather", serif;
    border: none;
}

.save:hover {
    background: rgb(161, 159, 159);
    color: rgb(255, 255, 255);
    font-family: "Merriweather", serif;
}

.updateShadow {
    box-shadow: 0 8px 4px 0 #afc1c7, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/** @format */

.SingleComponent {
    display: flex;
    -webkit-user-select: none;
            user-select: none;
    flex-direction: column;
    align-items: center;
    background-color: #ececec;
    border-radius: 2px;
    width: 70px;
    height: 72px;
    padding-top: 12px;
    text-align: center;
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab;
}

.icon-text {
    font-family: "Proxima Nova Soft";
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 2em;
    justify-content: center;
    max-width: 90%;
}

.icon-style {
    pointer-events: none;
    width: 30px;
    height: 30px;
    padding-bottom: 3px;
}

.single_component_wrapper {
    position: relative;
    margin-bottom: 10px;
}
/** @format */

.left-side-menu {
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
            user-select: none;
    background-color: white;
    font-family: Helvetica !important;
    font-size: 14px;
    min-width: 239px;
    font-weight: bold;
    flex: 0 0 239px;
    width: 100%;
}

.right-side-menu {
    display: flex;
    float: right;
    justify-content: flex-end;
    -webkit-user-select: none;
            user-select: none;
    background-color: white;
    min-width: 239px;
    flex: 0 0 239px;
    width: 100%;
    overflow: scroll;
}

.left-mini-menu {
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
            user-select: none;
    min-width: 130px;
    flex: 0 0 130px;
    font-family: Helvetica, sans-serif !important;
    width: 100%;
    position: relative;
}

.left-mini-menu div {
    align-items: flex-end;
    justify-content: end;
}

.left-mini-menu img {
    width: 50px;
    cursor: pointer;
}

.canvas {
    flex: 0 0 54rem;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 600px;
}

.container-direction {
    display: flex;
    height: 100%;
    min-height: 600px;
}

.designer-vertial-layout {
    position: relative;
    display: flex !important;
    flex-flow: column nowrap !important;
    min-height: 2%;
}

.designer-vertial-layout > * {
  
}

.single-key:hover {
    background: lightcoral;
    border: 1px solid lightblue;
}

.single-value:hover {
    background: lightcoral;
    border: 1px solid lightblue;
}
.nav-color {
    background: #fefefa;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.41);
    font-family: sans-serif;
}

.heading>h5 {
    font-family: "sans-serif";
    color: black;
}

.headingsFont {
    font-size: 20px;
}

.nav-bar {
    margin-bottom: 0px;
}

.navMobile {
    border-left: 1px solid black;
    padding-left: 22px;
    border-right: 1px solid black;
    padding-right: 22px;
    margin-left: 50px;
}

.navButton {
    background: #fefefa;
}

.links {
    margin-left: 20px;
    font-size: 15px;
    /* position: relative; */
}

.nav-link {
    color: black;
}

.nav-link:hover {
    color: #3472cd;
}

.nav-bar-items {
    margin-left: 10px;
}

#brand {
    margin: 0px 80px;
}

#dnd {
    color: #3472cd;
}

.buttons {
    margin-right: 60px;
}

.buttons>.start-button {
    width: 130px;
}

#color-button {
    background-color: white;
}

.fonts-for-others {
    margin-left: 20px;
    font-size: 15px;
    font-family: sans-serif;
}

.log-out {
    background-color: #3472cd;
    color: #f8f1d6;
    font-family: sans-serif;
}

.log-out:hover {
    background-color: black;
    color: #f8f1d6;
}

#color-button:hover {
    background-color: rgb(240, 230, 226);
}

.increase-width {
    width: 100px;
}
/** @format */
.inherit-dimensions {
    height: 700px;
    width: initial;
}

.inherit-width {
    width: inherit;
}

.inherit-height {
    height: inherit;
}
.tree,
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tree ul {
  margin-left: 1em;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5em;
}
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}
.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: #369;
  font-weight: 700;
  position: relative;
}
.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}
.tree ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0;
}
.indicator {
  margin-right: 5px;
}
.tree li a {
  text-decoration: none;
  color: #369;
}
.tree li button,
.tree li button:active,
.tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  outline: 0;
}

