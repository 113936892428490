.main-div {
  background: rgb(245, 242, 232);
}
.heading {
  text-align: center;
  padding: 20px;
  /* color: black !important; */

  font-family: "Times New Roman", Times, serif;
}
.left-side {
  margin: 10px 10px 0px 20px;
  background: rgb(245, 242, 232);
  -webkit-box-shadow: 0 0 10px #888888;
  box-shadow: 0 0 10px #888888;
  /* box-shadow: 0 8px 4px 0 #888888, 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding-bottom: 2px;
  /* background: grey; */
}
.item {
  /* font: bold; */
  /* font: bold 15px/30px Georgia, serif; */
  margin: 0px 10px 10px 10px !important;
  border-left: thick solid rgb(87, 87, 255) !important;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
}
.add-btn-section {
  border: 1px solid rgb(153, 151, 151) !important;
  padding: 0px;
}
.add-input {
  padding: 15px;
  margin: 15px 5px 15px 15px;
  width: 250px;
  border: 1px solid rgb(164, 164, 250);
  border-radius: 5px;
}

.right-side {
  margin: 10px 40px 0px 0px;
  padding: 10px;
  width: 50%;
  -webkit-box-shadow: 0 0 10px #888888;
  box-shadow: 0 0 10px #888888;
  /* box-shadow: 0 8px 4px 0 #888888, 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.remove-btn {
  background: rgb(87, 87, 255) !important;
  display: inline-block;
  color: white !important;
  border: none;
  border-radius: 20px !important;
  transition: all 0.4s ease 0s;
}
.remove-btn:hover {
  background: #ed3330 !important;
  letter-spacing: 1px;
  /* color: white; */
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}
.add-btn {
  border-radius: 50px;
  display: inline-block !important;
  border: none;
  transition: all 0.4s ease 0s;
  border-radius: 20px !important;
  padding: 10px;
  /* float: right; */
  /* align-content: right; */
  margin-bottom: 2px;
  /* margin: 0px 0px 2px 10px; */
  width: 70px;
}
.update-btn {
  border-radius: 50px;
  display: inline-block !important;
  border: none;
  transition: all 0.4s ease 0s;
  border-radius: 20px !important;
  padding: 10px;
  margin-bottom: 2px;
  width: 100px;
}
.add-btn:hover {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}
.column-input {
  padding: 15px !important;
  width: 300px;
  margin: 10px 20px 10px 15px;
  border: 1px solid rgb(164, 164, 250);
  border-radius: 5px;
}
.select_list {
  padding: 5px !important;
  border: 1px solid rgb(164, 164, 250);
  border-radius: 5px;
  width: 150px;
  margin: 10px 20px 10px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.column-add-btn {
  border-radius: 50px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
  width: 70px;
  border-radius: 20px !important;
  padding: 10px;
}
.column-add-btn:hover {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.47);
  transition: all 0.4s ease 0s;
}
.column-item {
  margin: 0px 10px 10px 10px !important;
  /* border-left: thick solid rgb(87, 87, 255) !important; */
  /* padding: 10px; */
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
}
.left-side-heading {
  text-align: center;
  font-size: 20px;
}
