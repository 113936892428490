/** @format */

.left-side-menu {
    display: flex;
    flex-direction: row;
    user-select: none;
    background-color: white;
    font-family: Helvetica !important;
    font-size: 14px;
    min-width: 239px;
    font-weight: bold;
    flex: 0 0 239px;
    width: 100%;
}

.right-side-menu {
    display: flex;
    float: right;
    justify-content: flex-end;
    user-select: none;
    background-color: white;
    min-width: 239px;
    flex: 0 0 239px;
    width: 100%;
    overflow: scroll;
}

.left-mini-menu {
    display: flex;
    flex-direction: column;
    user-select: none;
    min-width: 130px;
    flex: 0 0 130px;
    font-family: Helvetica, sans-serif !important;
    width: 100%;
    position: relative;
}

.left-mini-menu div {
    align-items: flex-end;
    justify-content: end;
}

.left-mini-menu img {
    width: 50px;
    cursor: pointer;
}

.canvas {
    flex: 0 0 54rem;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 600px;
}

.container-direction {
    display: flex;
    height: 100%;
    min-height: 600px;
}

.designer-vertial-layout {
    position: relative;
    display: flex !important;
    flex-flow: column nowrap !important;
    min-height: 2%;
}

.designer-vertial-layout > * {
  
}
