.carouselButton {
  border: none;
  border-radius: 3px;
  padding: 7px;
  background-color: rgb(53, 56, 59);
  color: white;
}
.carouselButton:hover {
  background-color: white;
  color: black;
}
.fiveSteps {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 50px;
}
.fiveStepsHeading {
  margin-top: 45px;
}
.videoDiv {
  background-color: rgb(53, 56, 59);
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 20px;
}
.VideoDivButton {
  background: rgb(53, 117, 201);
  color: rgb(233, 233, 233);
  font-size: 18px;
  width: 45%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  margin-left: 128px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
}
.VideoDivButton:hover {
  background: rgb(97, 130, 173);
  color: white;
  border: 1px solid rgb(97, 130, 173);
}
.VideoDivAlign {
  text-align: center;
  margin-top: 30px;
}
.iconsHome {
  font-size: 50px;
  color: rgb(53, 117, 201);
}
.iconsHome:hover {
  color: rgb(246, 200, 0);
}
.iconsDiv {
  margin-top: 100px;
  margin-bottom: 80px;
}
.banner {
  background: rgb(53, 56, 59);
}
.Banner-Btn {
  background: rgb(53, 117, 201);
  color: black;
  font-size: 18px;
  width: 50%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  margin: 30px 20px 20px 100px;
  text-align: center;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
}
.Banner-Btn:hover {
  background: black;
  color: rgb(53, 117, 201);
}
.Banner-text {
  color: #fff;
  text-transform: none;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 500;
  padding: 20px;
  margin: 20px;
}
.Banner-text > b {
  color: rgb(53, 117, 201);
}
.MobileDiv {
  margin-top: 50px;
  margin-bottom: 100px;
}
.mobDivStyle {
  padding-top: 30px;
}
.mobDivButton {
  background: rgb(53, 117, 201);
  color: black;
  font-size: 18px;
  width: 48%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  text-align: center;
  box-sizing: border-box;
  border: solid 2px black;
  border-radius: 5px;
}
.mobDivButton:hover {
  background: rgb(97, 130, 173);
  color: black;
  border: solid 2px black;
}
.mobDivButton2 {
  background: whitesmoke;
  color: black;
  font-size: 18px;
  width: 48%;
  height: 50px;
  line-height: 50px;
  padding: 0px;
  margin-left: 5px;
  text-align: center;
  box-sizing: border-box;
  border: solid 2px black;
  border-radius: 5px;
}
.mobDivButton2:hover {
  background: rgb(223, 219, 219);
  color: black;
  border: solid 2px black;
}
.faqHome {
  margin: 50px 20px 20px 20px;
  background: rgb(53, 117, 201);
  color: rgb(53, 56, 59);
  width: 100%;
  text-align: center;
}
.faqHomeQues {
  margin-top: 40px;
  margin-bottom: 40px;
  /* text-align: center; */
}
.MotivationHeading {
  color: black;
}
.motivation-text {
  text-align: left;
  color: black;
  margin: 10px;
  padding: 30px;

  text-decoration-style: solid;
}
.motivationBackground {
  background-image: linear-gradient(
    to right,
    #ffffff,
    #eafaff,
    #3b6edd,
    #0f57f1
  );
}
