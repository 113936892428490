.login-page {
    background-image: url("../SiteImages/back.jpg");
    /* float: right; */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: fixed;
    width: 100%;
    font-family: "Numans", sans-serif;
}

.container {
    height: 100%;
    align-content: center;
}

.card {
    /* background-color: rgba(0, 0, 0, 0.8) !important; */
    /* background: rgb(218, 216, 216); */
    opacity: 0.9;
    border-left: 5px solid grey;
    border-bottom: 5px solid grey;
    border-right: 5px solid grey;
    border-top: 5px solid grey;
    box-shadow: 1px 1px 2px rgb(39, 39, 39), 0 0 25px rgb(25, 25, 41), 0 0 5px darkblue;
    /* float: right; */
    /* margin-right: 0px; */
    /* border-right: 1px rgb(53, 117, 201); */
    height: 480px;
    margin-top: 80px;
    margin-bottom: auto;
    width: 450px;
}

.login {
    margin-top: 15px;
    color: white;
}

.input-group>.form-control {
    border-bottom-left-radius: 0;
    height: auto;
}

.social_icon span {
    font-size: 40px;
    margin-left: 10px;
}

.fa-facebook-square {
    color: rgb(66, 103, 178);
}

.fa-facebook-square:hover {
    color: rgb(66, 50, 178);
}

.fa-google-plus-square {
    color: rgb(221, 75, 57);
}

.fa-github-square {
    color: rgb(34, 32, 32);
}

.fa-google-plus-square:hover {
    color: rgb(221, 30, 57);
}

.fa-twitter-square {
    color: rgb(56, 161, 243);
}

.fa-twitter-square:hover {
    color: rgb(56, 120, 243);
}

.fa-github-square:hover {
    color: rgb(8, 8, 8);
}


/* .icon:hover {
    color: rgb(53, 117, 201);
    cursor: pointer;
  } */

.card-header b {
    color: rgb(70, 68, 68);
    margin-bottom: 25px;
    /* font-family: "stag Web"; */
    font-size: 40px;
}

.card-header {
    margin-bottom: 20px;
}

.social_icon {
    margin-left: 0px;
}


/* .social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
} */

.extra-styles span {
    width: 50px;
    background-color: rgb(53, 117, 201);
    color: black;
    border: 0;
}

.sample span {
    width: 50px;
    background-color: #E8F0FE !important;
    color: rgb(77, 91, 100) !important;
    /* border:1px solid #E8F0FE !important; */
    /* border: 0 !important; */
    cursor: pointer;
    user-select: none;
    justify-content: center;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn {
    color: black;
    background-color: rgb(53, 117, 201);
    border-radius: 3px;
    border: none;
    padding: 5px;
    margin: 15px 0px 0px 0px;
    width: 100px;
    align-content: center;
}

.login_btn:hover {
    color: white;
    background-color: rgb(0, 0, 0);
}

.link {
    color: black;
}

.link a {
    margin-left: 4px;
}

.card-footer {
    margin-top: 0px;
}