.footer {
  background-color: rgb(20, 27, 41);

  padding: 20px;
  color: aliceblue;
  text-align: center;
}
.FooterHeadings {
  color: rgb(53, 117, 201);
}
.section {
  text-align: left;
  padding: 20px;
  color: white;
}
.section a {
  text-decoration: none;
  color: white;
}

.copyright {
  text-align: center;
  background-color: #1e242b;
  padding: 10px;
}
.copyright a {
  text-decoration: none;
  color: white;
}
