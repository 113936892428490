.sidebar {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 16px;
}

.sidebar a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.right-side {
  margin: 10px;
  padding: 5px;
  /* -moz-border-radius: 5px;
	-webkit-border-radius: 5px;  */
  border: 3px solid black;
  border-style: double;
}

.zero_padding {
  padding: 0px !important;
  margin: 0px !important;
}