/* .gridStructure {
  grid-column-start: 1;
  grid-column-end: 3;
} */
.rightSideIcons {
  padding-bottom: 20px;
  cursor: pointer;
  color: darkslategray;
  margin-top: 30px;
  font-size: 20px;
}

.rightSideIcons:hover {
  color: #0069D9;

}

.showFew>div {
  border: 1px solid lightgray;
}