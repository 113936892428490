/** @format */

.SingleComponent {
    display: flex;
    user-select: none;
    flex-direction: column;
    align-items: center;
    background-color: #ececec;
    border-radius: 2px;
    width: 70px;
    height: 72px;
    padding-top: 12px;
    text-align: center;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.icon-text {
    font-family: "Proxima Nova Soft";
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 2em;
    justify-content: center;
    max-width: 90%;
}

.icon-style {
    pointer-events: none;
    width: 30px;
    height: 30px;
    padding-bottom: 3px;
}

.single_component_wrapper {
    position: relative;
    margin-bottom: 10px;
}