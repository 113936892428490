.card-about {
  padding: 40px;
  display: block;
  background: rgb(255, 255, 255);
  margin-top:15px;
  /* margin: 20px 0px 20px 0px; */
}
.about-page {
  background: #f5f7fa;
  /* margin-top:10px; */
}
.about {
  margin: 15px;
  padding: 10px;
}
.content {
  padding: 20px;
}
.faq {
  margin: 20px;
  /* background: rgb(246, 200, 81); */
  background: #3575C9;
  width: 100%;
  margin-left: 0px;
  text-align: center;
}
.img-circle {
  border-radius: 50%;
  width: 112px;
}
.leader {
  text-align: center;
  margin-top: 55px;
  color: rgb(61, 60, 60);
}
