.contactUs{
    /* width: 100%; */
    font-family: "Numans", sans-serif;
    margin-left:20px;
    
}
#submit_button{
    background: rgb(53, 117, 201);
    color:white;
    border-radius: 5px;
    padding:10px;
    border:none;
}
#submit_button:hover{
    background: rgb(103, 147, 204);
    color:white;
    border-radius: 5px;
    padding:10px;
    border:none;
}