@import url("https://fonts.googleapis.com/css?family=Merriweather&display=swap");
.nav-color {
    background: #fefefa;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.41);
    font-family: sans-serif;
}

.heading>h5 {
    font-family: "sans-serif";
    color: black;
}

.headingsFont {
    font-size: 20px;
}

.nav-bar {
    margin-bottom: 0px;
}

.navMobile {
    border-left: 1px solid black;
    padding-left: 22px;
    border-right: 1px solid black;
    padding-right: 22px;
    margin-left: 50px;
}

.navButton {
    background: #fefefa;
}

.links {
    margin-left: 20px;
    font-size: 15px;
    /* position: relative; */
}

.nav-link {
    color: black;
}

.nav-link:hover {
    color: #3472cd;
}

.nav-bar-items {
    margin-left: 10px;
}

#brand {
    margin: 0px 80px;
}

#dnd {
    color: #3472cd;
}

.buttons {
    margin-right: 60px;
}

.buttons>.start-button {
    width: 130px;
}

#color-button {
    background-color: white;
}

.fonts-for-others {
    margin-left: 20px;
    font-size: 15px;
    font-family: sans-serif;
}

.log-out {
    background-color: #3472cd;
    color: #f8f1d6;
    font-family: sans-serif;
}

.log-out:hover {
    background-color: black;
    color: #f8f1d6;
}

#color-button:hover {
    background-color: rgb(240, 230, 226);
}

.increase-width {
    width: 100px;
}