/** @format */

.noScrollBar::-webkit-scrollbar {
    display: none !important;
}


/* The device with borders */

div[id^="component"],
div[id^="label"] {
    cursor: grab;
}

.tablet {
    box-sizing: border-box;
    position: relative;
    margin: 0 40px;
    text-align: start;
    background-color: white !important;
    min-height: 600px;
    height: 700px;
    box-shadow: 0px 10px 1px #ddd, 0 10px 20px #ccc;
}


/* The horizontal line on the  top of the device */

.horizontal_layout {
    position: relative;
    min-height: 2%;
}

.canvas-toolbar .left-arrow {
    /* top: 38%; */
    /* left: 25%; */
    /* margin-left: 40px; */
}

.canvas-toolbar .right-arrow {
    /* margin-right: 40px; */
    justify-content: center;
    align-items: center;
}

.box:hover {
    width: 15px;
    height: 15px;
    border: 2px solid #80ddf8;
}

.arrow-container {
    align-items: center;
}

.canvas-toolbar {
    user-select: none;
    position: relative;
    display: flex;
    /* flex-grow: 1; */
    align-items: center;
    justify-content: center;
    /* display: inline-block; */
    /* text-align: center; */
    /* display: flex; */
    /* align-content: center;
    flex-direction: row;
    vertical-align: middle; */
}

.canvas-toolbar img {
    width: auto;
    height: auto;
    cursor: pointer;
}

.tablet-topbar-styling {
    background: #ffffff;
    width: 100%;
    height: 15px;
    box-shadow: 0px 10px 1px #ddd 0 10px 20px #ccc;
}

.tablet-topbar-styling img {
    float: right;
    height: 15px;
}

.border-childs {
    position: absolute;
    width: 2px;
    height: 2px;
    border: 2px solid #0069d9;
    z-index: 1000031;
    background-color: #0069d9;
    cursor: grab;
}

.top-left-border {
    top: 0;
    left: 0;
}

.top-center-border {
    top: 0;
    left: 50%;
}

.top-right-border {
    top: 0;
    right: 0;
}

.bottom-left-border {
    bottom: -10px;
    left: 0;
    cursor: row-resize;
}

.bottom-center-border {
    bottom: -10px;
    left: 50%;
    cursor: row-resize;
}

.bottom-right-border {
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
}