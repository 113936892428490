.center {
    /* display: inline;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0) */
}

.right {
    /* position: relative; */
    float: right;

}

.left {
    /* position: relative; */
    float: left;

}