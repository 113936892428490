/**
 * /* OTHER BUTTON
 *
 * @format
 */

.example_1 {
    background: #1d1d1d;
    color: white;
    padding: 10px;
}

.example_2 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    border: none;
}

.example_10 {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: 2px solid white;
    border-radius: 50px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    /* outline: none; */
}


/* OTHER BUTTON */

.example_3 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #60a3bc;
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    border: none;
}


/* OTHER BUTTON */

.example_4 {
    border: none;
    background: #404040;
    color: #ffffff;
    font-weight: 100;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 6px;
    display: inline-block;
}


/* OTHER BUTTON */

.example_5 {
    background: #ed3330;
    border: none;
    padding: 10px;
    border-radius: 10px;
}

.example_6 {
    background: white;
    border: 2px solid rgb(85, 85, 85);
    color: rgb(85, 85, 85);
    padding: 10px;
}

.example_10 {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: 2px solid white;
    border-radius: 50px;
    width: 150px;
    cursor: pointer;
    overflow: hidden;
    /* outline: none; */
}

.example_7 {
    border-radius: 50px;
    border-style: dotted;
}

.example_8 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    /* padding-bottom: 30px ; */
    text-align: center;
    display: table-cell;
    padding-top: 3px;
    vertical-align: middle;
    background: #9db416;
    color: white;
}

.example_9 {
    border-radius: 5px 25px;
    background: #edb130;
}

.button {
    outline: none;
}

.example_12 {
    border: none;
    background: #0069d9;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 30px;
}


/* Areeb Bhai Css */

.label {
    color: #757575;
    font-family: Verdana;
}

.label-2 {
    color: #757575;
    font-family: sans-serif;
    color: black;
}

.editText {
    background-color: #ff4437;
    border: 0;
    padding: 10px 20px 10px 20px;
    color: white;
}

.editText:focus {
    outline: none;
    box-shadow: none;
    color: black;
    background-color: #dddddd;
}

.editText::placeholder {
    color: white;
    opacity: 1;
}

.editText:focus::placeholder {
    color: red;
    opacity: 1;
}

.editText-2 {
    background-color: #ffffff;
    border-top: none;
    outline: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
    padding: 10px 20px 10px 20px;
    color: black;
}


/* .editText-2:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #ff4437;
    color: black;
    background-color: #dddddd;
} */


/* Musa Style Sheet */

.textField_1 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 20px;
    background: white;
}

.textField_2 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    box-sizing: border-box;
    border: 1px solid black;
    background: white;
}

.textField_3 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid black;
    background: rgb(230, 49, 49);
}

.textField_4 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid rgb(41, 163, 41);
    border-radius: 10px;
    background: rgb(41, 163, 41);
    color: black;
}

.textField_5 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid;
    border-radius: 5px;
}

.textField_6 {
    width: 95%;
    padding: 15px;
    margin: 8px;
    border: 2px solid rgb(236, 232, 232);
    border-left: 5px solid black;
    background: rgb(236, 232, 232);
}

.textField_1::placeholder {
    color: black;
}

.textField_2::placeholder {
    color: black;
}

.textField_3::placeholder {
    color: black;
}

.textField_4::placeholder {
    color: black;
}

.textField_6::placeholder {
    color: black;
}

.radio-styles {
    width: 40px;
    height: 40px;
}

.checbox_default {
    width: 2.5rem;
    height: 2.5rem;
}