/** @format */

@import url("https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto:wght@700&display=swap");
.inherit-dimensions {
    height: 700px;
    width: initial;
}

.inherit-width {
    width: inherit;
}

.inherit-height {
    height: inherit;
}